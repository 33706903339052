<script setup>
const props = defineProps(['tableData'])
const mainFields = ['comments', 'engagement', 'replies', 'bot_replies', 'human_edits', 'autonomous', 'human_edited']
</script>
<template>
  <div class="my-10 px-3 py-1 bg-gray-50 border border-gray-100 rounded-xl">
    <table class="builder-table text-gray-600 table-fixed w-full rounded-xl">
      <thead>
        <tr>
          <th>#</th>
          <th>Campaign Name</th>
          <th>Comments</th>
          <th>Engagements</th>
          <th>Replies</th>
          <th>Bot Assisted Replies</th>
          <th>Human Assisted Replies</th>
          <th>% of Autonomous Replies</th>
          <th>% of Human Assisted Replies</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in Object.entries(tableData)" :key="row[0]">
          <td>{{ row[0] }}</td>
          <td>{{ row[1].name }}</td>
          <td v-for="field, index in mainFields" :key="index">
            {{ row[1].analytics[field] || '-' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
.builder-table {
  border-collapse: separate;
  border-radius: 12px;
  /* border: 1px solid #ddd; */
  /* border-spacing: 0;
  border-radius: 24px;
  border: 1px solid #ddd;
  background: #fafafa;
  border-collapse: separate;
  border-spacing: 0 10px; */
  border-spacing: 0 10px;
}
.builder-table tr th {
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0 0.5rem;
}
.builder-table tr th:first-child {
  text-align: center;
  padding: 0;
}
.builder-table tbody tr {
  background: #fff;
}
.builder-table tr td:first-child {
  padding: 0;
  text-align: center;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.builder-table tr td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.builder-table tr td {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
}
</style>
