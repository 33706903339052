export const isoDateForm = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const getLast7Days = () => {
  const today = new Date()
  const sevenDaysAgo = new Date(today)
  sevenDaysAgo.setDate(today.getDate() - 7)

  return { from: isoDateForm(sevenDaysAgo), to: isoDateForm(today) }
}

export function shortFormDate(date) {
  date = new Date(date)
  const day = new Date(date).getDate()
  const month = date.toLocaleString("default", { month: "short" })
  const year = date.getFullYear()
  const suffix =
    ["st", "nd", "rd"][((((day + "").slice(-1) < 4) * day) % 10) - 1] || "th"
  return `${day}${suffix} ${month}, ${year}`
}
