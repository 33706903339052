<script setup>
import { shortFormDate } from '~/src/utils/dates'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const dates = ref([new Date(), new Date()])
const datePicker = ref(null)
const props = defineProps(['currentTab', 'tabs', 'onTabChange', 'currentDates', 'onDateChange', 'onOptionSelect', 'allFilters'])
const isOpen = ref({ workflow: false, platform: false, post: false });
const selectedOption = ref({ id: 0, name: "All" });

const makeOpts = (tabKey) => {
  return [{ id: 0, name: "All" }].concat(Object.entries(props.allFilters[tabKey]).map(([id, opt]) => ({ id, name: opt.name || opt.title || `${tabKey} # ${id}` })))
}

const handleDate = (newVal, index) => {
  const newDates = [...dates.value]
  newDates[index] = newVal
  dates.value = newDates
  if (index && datePicker.value) {
    props.onDateChange(dates.value)
    datePicker.value.closeMenu()
  }
}
const openTabMenu = (tab) => {
  isOpen.value = { ...isOpen.value, [tab]: !isOpen.value[tab] }
}
const handleDropdownBlur = (tab) => {
  isOpen.value = { ...isOpen.value, [tab]: false }
}
const selectOption = (tab, option) => {
  props.onOptionSelect(option.id, tab)
  selectedOption.value = option;
}
</script>

<template>
  <div class="flex my-6 justify-between">
    <div class="flex border rounded-2xl p-1 gap-2 text-gray-600">
      <div v-for="tab in tabs" :key="tab.key" class="relative inline-block text-left">
        <div>
          <button
            id="menu-button"
            @click="openTabMenu(tab.key)"
            v-click-outside="() => handleDropdownBlur(tab.key)"
            type="button"
            :class="`inline-flex w-full justify-center gap-x-1.5 rounded-xl px-3 py-2 text-sm font-semibold ${currentTab === tab.key ? 'bg-black text-white' : 'bg-white text-black'} shadow-sm`"
            aria-expanded="true"
            aria-haspopup="true"
          >
            {{ currentTab === tab.key && selectedOption.id ? selectedOption.name : tab.label }}
            <svg class="mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div :class="isOpen[tab.key] ? 'absolute' : 'hidden'" class="z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 w-35 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="" role="none">
            <a v-for="option, index in makeOpts(tab.key).filter(option => currentTab === tab.key && selectedOption.id || option)" @click="selectOption(tab.key, option)" :key="index" :id="`menu-item-${index}`" href="#" :class="`text-gray-700 block px-4 py-2 text-sm ${currentTab === tab.key && selectedOption.id === option.id ? 'font-semibold' : ''}`" role="menuitem" tabindex="-1">
              {{ option.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex cursor-pointer">
      <VueDatePicker ref="datePicker" range :enable-time-picker="false" @range-start="val => handleDate(val, 0)" @range-end="val => handleDate(val, 1)">
        <template #trigger>
          <div class="flex border p-1 mr-2 rounded-2xl items-center bg-white">
            <button class="text-sm h-full text-gray-700 px-4 py-2 rounded-xl bg-gray-100">
              {{shortFormDate(currentDates[0])}}
            </button>
            <p class="text-gray-500 mx-2">
              to
            </p>
            <button class="text-sm h-full text-gray-700 px-4 py-2 rounded-xl bg-gray-100">
              {{shortFormDate(currentDates[1])}}
            </button>
          </div>
        </template>
        <template #action-preview>
        </template>
        <template #action-buttons>
        </template>
      </VueDatePicker>
    </div>
  </div>
</template>

<style>
.dropdown {
  border-radius: 0.75rem; /* Add border radius */
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.arrow-icon {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}

.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.white-style {
  background-color: white;
  color: black;
}

.black-style {
  background-color: black;
  color: white;
}

.arrow-icon-white {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}

.arrow-icon-black {
  border: solid white; /* Adjusted color for black style */
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  margin-left: 5px;
}
</style>