<script setup>
import { Line } from 'vue-chartjs'
const props = defineProps(['currentCard', 'cards', 'onCardChange', 'allStats'])

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
      scales: {
        beginAtZero: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}

const data = computed(() => {
  return {
    labels: props.allStats.metrics?.date || [],
    datasets: [
      {
        label: 'Replies posted',
        backgroundColor: '#5644c9',
        borderColor: '#5644c9',
        data: props.allStats.metrics?.value?.[props.currentCard] || [],
        fill: false,
        pointRadius: 4,
        pointStyle: 'circle',
        pointBorderWidth: 12,
        pointBorderColor: 'rgba(86, 68, 201, 0.2)',
      },
    ],
  }
})
const fetchStatValue = (key) => {
  const statObj = props.allStats?.aggregate || {}
  if (statObj[key] !== undefined) return statObj[key].toLocaleString()
}
</script>

<template>
  <div>
    <div class="grid grid-cols-7 gap-4">
      <div v-for="card in cards" :key="card.key" class="rounded-xl p-[2px] cursor-pointer bg-gray-300"
        :class="{ 'bg-mythos-violet': currentCard === card.key }" @click="onCardChange(card.key)">
        <div class="col-span-1 overflow-hidden rounded-[0.625rem] p-4 bg-white">
          <p class="text-sm text-ellipsis overflow-hidden whitespace-nowrap">{{ card.label }}</p>
          <p class="text-2xl text-black font-bold">{{ fetchStatValue(card.key) }}</p>
        </div>
      </div>
    </div>
    <div class="w-full h-fit bg-white rounded-xl border shadow mt-8">
      <div class="mt-4 px-5 pt-10 pb-5 min-h-[20rem]">
        <Line :data="data" :options="options" />
      </div>
    </div>
  </div>
</template>
